<template>
    <div class="news-detail-page">
        <div class="cont">
            <div class="wid1200" style="margin-bottom: 20px;">
                <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
            </div>
            <el-row>
                <el-col :span="17">
                    <div class="ltCont">
                        <h5 class="title">
                            <img v-if="$route.name=='pD'" src="../assets/img/preprint.jpg" alt="">
                            {{detail.title}}</h5>
                        <div class="clear-fix pdate" v-if="shareShow">
                            <div class="fl">作者：<span>{{detail.author}}</span></div>
                            <div class="fl">发布时间：<span>{{detail.releaseDate}}</span></div>
                            <div class="fl img-wrap5 com-img">
                                <img src="../assets/img/shareicon.png" alt="" />
                                <div class="code-img-wrap5 com-img-wrap" style=" z-index: 20;">
                                    <div class="codeImg" id="codeImg" ref="qrcodeContainers"></div>
                                </div>
                            </div>
                            <div class="clear" style="clear:both;"></div>
                            <!-- <div class="bdsharebuttonbox fr" data-tag="share_1">
                                <a class="bds_sqq bds_sqq1" data-cmd="sqq"></a>
                                <a class="bds_weixin bds_weixin1" data-cmd="weixin" @click="shareWechat = true "></a>
                                <a class="bds_tsina" data-cmd="tsina"></a>
                            </div> -->
                        </div>
                        <div v-if="detail.mediaType=='png'" class="infoCont">
                            <img :src="detail.mediaPath" alt="" />
                        </div>
                        <!-- <div v-else-if="detail.mediaType=='zip' && detail.mediaPath"  class="infoCont">
                            <span class="download-zip" @click="downloadZip">下载附件</span>
                        </div> -->
                        <div v-else class="infoCont" v-html="detail.content"></div>


                        <div class="showPdf" style="width: 100%; height: 1010px; z-index: 1;" v-if="detail.attachment">
                            <div width="100%" height="1000">
                                <iframe :src="'/pdf/web/viewer.html?file=/admin'+url" width="100%" height="1000"
                                    frameborder="0">
                                    <a
                                        :href="'/pdf/web/viewer.html?file=/admin'+url">你的浏览器不支持iframe页面嵌套，请点击这里访问页面内容。</a>
                                </iframe>
                            </div>
                            <a class="down" @click="download(detail.id)">
                                <img src="../assets/img/download1.png" alt="">
                            </a>
                        </div>
                        <a id="downLaodAticle" style="display:none;"
                            :href="'/admin/api/aloneArticle/downLoad?id='+detail.id">下载阅读</a>

                        <!-- <a id="downLaodAticle" style="display:none;" :href="url" :download="detail.title">下载文章</a> -->
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="tab-rt">

                        <div class="relevant-article-wrap">
                            <div class="com-title">
                                <div>
                                    <img class="title-img" src="../assets/img/icon3.png" alt="" />
                                    <h5>最新文章</h5>
                                </div>
                            </div>

                            <div class="relevant-article">
                                <ul>
                                    <li v-for="item in latestNews" :key="item.id" @click="goRelevant('article', item)">{{item.searchTitle ? item.searchTitle : item.title}}</li>
                                </ul>
                            </div>

                        </div>

                        <div class="relevant-anthology-wrap">
                            <div class="com-title">
                                <div>
                                    <img class="title-img" src="../assets/img/icon1.png" alt="" />
                                    <h5>最新集刊</h5>
                                </div>
                            </div>
                            <div class="relevant-anthology">
                                <img v-if="bkList[0] && bkList[0].titleImg" :src="bkList[0].titleImg" alt=""
                                    @click="goRelevant('wenji', bkList[0])" style="cursor:pointer;" />
                                <img v-else alt="" />
                                <div class="relevant-anthology-main">
                                    <h5 @click="goRelevant('wenji', bkList[0])" v-if="bkList[0] && bkList[0].title">
                                        {{bkList[0].title}}</h5>
                                    <p v-if="bkList[0] && bkList[0].author">主编：{{bkList[0].author}}</p>
                                    <p v-if="bkList[0] && bkList[0].abstractCn">集刊简介：{{bkList[0].abstractCn}}</p>
                                </div>
                            </div>
                            <ul>
                                <li v-for="(item, index) in bkList" :key="index" v-if="index>0 && item.title"
                                    @click="goRelevant('book', item)">{{item.title}}</li>
                            </ul>
                        </div>

                        <!-- <div class="iedolImg clear-fix" v-for="iterm in bkList " :key="iterm.id"
                                @click="toJk(iterm.id,iterm.title,iterm.collectnum)">
                                <img class="fl" :src="iterm.titleImg" v-if="iterm.titleImg" alt="">
                                <div class="fl iedolMsg">
                                    <p class="itle">{{iterm.title}}</p>
                                    <div class="author">主编：{{iterm.author}}</div>
                                    <p class="abs">{{iterm.abstractCn}}</p>
                                </div>
                                <div style="clear:both;"></div>
                            </div> -->

                        <!-- <div class="guss">
                            <h5><span>最新文章</span></h5>
                            <ul>
                                <li v-for="(item, index) in latestNews" :key="index">
                                    <router-link :to="{name:'iedolDetail',params:{id:item.id}}">{{item.title}}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="guss">
                            <h5><span>最新集刊</span></h5>
                            <div class="iedolImg clear-fix" v-for="iterm in bkList " :key="iterm.id"
                                @click="toJk(iterm.id,iterm.title,iterm.collectnum)">
                                <img class="fl" :src="iterm.titleImg" v-if="iterm.titleImg" alt="">
                                <div class="fl iedolMsg">
                                    <p class="itle">{{iterm.title}}</p>
                                    <div class="author">主编：{{iterm.author}}</div>
                                    <p class="abs">{{iterm.abstractCn}}</p>
                                </div>
                                <div style="clear:both;"></div>
                            </div>
                        </div> -->

                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- <div class="wechat_box" v-show="shareWechat">
            <h5><span>分享到微信朋友圈</span><a @click="shareWechatHandle">×</a></h5>
            <div class="codeImg" id="codeImg" ref="qrcodeContainer"></div>
            <p>打开微信，点击底部的“发现”，</p>
            <p>使用“扫一扫”即可将网页分享至朋友圈。</p>
        </div> -->
        <div id="copy" class="copy" data-clipboard-action="copy"
            style="position: absolute; left: 0; top: 0; display: none;" ref="copy" @click="copyLink($event)"></div>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import BreadCrumb from '../components/BreadCrumb'

export default {
    data() {
        return {
            pageName: '详情',
            pN: '',
            id: '',
            curTab: 1,
            tabs: [
                { name: '摘要', id: 1 },
                { name: '全文', id: 2 },
                { name: '作者', id: 3 },
                { name: '参考文献', id: 4 }
            ],
            detail: '',
            latestNews: [],
            bkList: [],
            shareShow: true,
            shareWechat: false,
            downloadUrl: '',
            url: ''
        }
    },
    beforeCreate() {
        window._bd_share_main = ''
    },
    components: {
        // QRCode,
        BreadCrumb
    },
    mounted() {
        var _this = this
        setTimeout(() => {
            // _this.setShare()
        }, 0)
    },
    created() {
        this.init()
        // this.getDetail(this.$route.query.id)
        // this.getLatestNews()
        // this.getLastJk()
        // this.showQRCode()
    },
    watch: {
        $route(to, from) {
            // this.getDetail(this.$route.query.id)
            this.init()
        }
    },
    methods: {
        init() {
            var str = window.location.href
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }
            if (str.indexOf('#') >= 0) {
                window.location.href = str.split('#')[0]
            }
            this.id = this.$route.query.id
            if (this.id == '1757277' || this.id == '1757278' || this.id == '1757279' || this.id == '1757280') {
                this.shareShow = false
            }

            if (this.$route.name == 'pD') {
                //预印本文章详情
                this.getPreprintArticle(this.$route.query.id)
            } else if (this.$route.name == 'eDA') {
                this.getExpertArticle(this.$route.query.id)
            } else if (this.$route.name == 'iDA') {
                this.getInsArticle(this.$route.query.id)
            } else {
                this.getDetail(this.$route.query.id)
            }

            this.getLatestNews()
            this.getLastJk()
            this.showQRCode()
        },
        // downloadZip () {
        //     const a = document.createElement('a')
        //     a.href = this.detail.mediaPath
        //     // a.download = filename
        //     a.click()
        //     window.URL.revokeObjectURL(blobUrl)
        // },
        getExpertArticle(id) {
            var _this = this
            var $ = $
            this.$http({
                method: 'get',
                url: '/admin/api/scholar/resourceDetail?resourceId=' + id
            }).then(res => {
                _this.detail = res.data.data
                _this.pageName = _this.detail.title
                if (_this.detail.appendix) {
                    _this.url = _this.detail.appendix.split('/admin')[1]
                    // console.log('_this.url', _this.url)
                }
                setTimeout(function() {
                    // var notes = $('.infoCont')
                    // console.log(3333, notes.find('span').text(),444,notes.find('a').children('span').children('strong').children('span').text())
                    // notes.find('a').css({'color': '#21d2f3 !important'})
                    // if (notes.find('a').children('span').children('strong').children('span').text() == '引用信息：') {
                    //     console.log(111,$(this), $(this).text())
                    // }
                    // var notes = document.getElementsByClassName('infoCont')
                    // for(var i=0;i<notes.length;i++){
                    // console.log(8,notes[i])
                    // notes[i].innerHTML = "<font style=\"color:red\">"+(i+1)+"</font>";
                    // }
                    // console.log(9,$('a').children('strong').text())
                    $('.infoCont p').each(function() {
                        if (
                            $(this)
                                .children('a')
                                .children('strong')
                                .text() == '引用信息：'
                        ) {
                            $(this)
                                .children('a')
                                .css('cursor', 'default')
                            var copyInfo = $(this).next()
                            var copyText = $(this)
                                .next()
                                .text()
                            $(this)
                                .next()
                                .css('cursor', 'pointer')
                            $(this)
                                .next()
                                .click(function(e) {
                                    var c = document.getElementById('copy')
                                    c.style.display = 'none'
                                    c.setAttribute('data-clipboard-text', '')
                                    var copy = _this.clickCopy(e)
                                    var toLeft1 = copy.x + 10
                                    var toTop1 = copy.y - 45
                                    c.style.left = toLeft1 + 'px'
                                    c.style.top = toTop1 + 'px'
                                    c.innerHTML = '一键复制'
                                    var str1
                                    c.setAttribute('data-clipboard-text', copyText)
                                    c.style.display = 'block'
                                    var overTop1 = toTop1 + c.clientHeight - $(window).height() - $(window).scrollTop()
                                    var overLeft1 = toLeft1 + c.clientWidth - $(window).width() - $(window).scrollLeft()

                                    if (overTop1 > 0) {
                                        toTop1 = toTop1 - overTop1 - 2
                                        c.style.top = toTop1 + 'px'
                                    }
                                    if (overLeft1 > 0) {
                                        toLeft1 = toLeft1 - overLeft1 - 2
                                        c.style.left = toLeft1 + 'px'
                                    }

                                    // notes.onmouseout=function(){
                                    //     c.style.display = 'none';
                                    // }

                                    // }
                                })
                        } else {
                        }
                    })
                }, 500)
            })
        },
        getInsArticle(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/research/resourceDetail?resourceId=' + id
            }).then(res => {
                _this.detail = res.data.data
                _this.pageName = _this.detail.title
                if (_this.detail.appendix) {
                    _this.url = _this.detail.appendix.split('/admin')[1]
                    // console.log('_this.url', _this.url)
                }
                setTimeout(function() {
                    // var notes = $('.infoCont')
                    // console.log(3333, notes.find('span').text(),444,notes.find('a').children('span').children('strong').children('span').text())
                    // notes.find('a').css({'color': '#21d2f3 !important'})
                    // if (notes.find('a').children('span').children('strong').children('span').text() == '引用信息：') {
                    //     console.log(111,$(this), $(this).text())
                    // }
                    // var notes = document.getElementsByClassName('infoCont')
                    // for(var i=0;i<notes.length;i++){
                    // console.log(8,notes[i])
                    // notes[i].innerHTML = "<font style=\"color:red\">"+(i+1)+"</font>";
                    // }
                    // console.log(9,$('a').children('strong').text())
                    $('.infoCont p').each(function() {
                        if (
                            $(this)
                                .children('a')
                                .children('strong')
                                .text() == '引用信息：'
                        ) {
                            $(this)
                                .children('a')
                                .css('cursor', 'default')
                            var copyInfo = $(this).next()
                            var copyText = $(this)
                                .next()
                                .text()
                            $(this)
                                .next()
                                .css('cursor', 'pointer')
                            $(this)
                                .next()
                                .click(function(e) {
                                    var c = document.getElementById('copy')
                                    c.style.display = 'none'
                                    c.setAttribute('data-clipboard-text', '')
                                    var copy = _this.clickCopy(e)
                                    var toLeft1 = copy.x + 10
                                    var toTop1 = copy.y - 45
                                    c.style.left = toLeft1 + 'px'
                                    c.style.top = toTop1 + 'px'
                                    c.innerHTML = '一键复制'
                                    var str1
                                    c.setAttribute('data-clipboard-text', copyText)
                                    c.style.display = 'block'
                                    var overTop1 = toTop1 + c.clientHeight - $(window).height() - $(window).scrollTop()
                                    var overLeft1 = toLeft1 + c.clientWidth - $(window).width() - $(window).scrollLeft()

                                    if (overTop1 > 0) {
                                        toTop1 = toTop1 - overTop1 - 2
                                        c.style.top = toTop1 + 'px'
                                    }
                                    if (overLeft1 > 0) {
                                        toLeft1 = toLeft1 - overLeft1 - 2
                                        c.style.left = toLeft1 + 'px'
                                    }

                                    // notes.onmouseout=function(){
                                    //     c.style.display = 'none';
                                    // }

                                    // }
                                })
                        } else {
                        }
                    })
                }, 500)
            })
        },
        copyLink(e) {
            var _this = this
            let clipboardObj = new this.clipboard('.copy')
            clipboardObj.on('success', function() {
                // console.log('success')
                e.target.style.display = 'none'
            })
            clipboardObj.on('error', function() {
                // console.log('error')
            })
        },
        goRelevant(type, item) {
            // console.log('item', item)
            if (type == 'wenji' || type == 'book') {
                this.$router.push({ path: '/collectionDetail/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
            } else if (type == 'article') {
                this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
                // title: item.title
            }
        },

        download(id) {
            // console.log('idididid', id)
            // var _this = this
            // _this.$http.get('/admin/api/aloneArticle/downLoad',{
            //     params:{
            //         id:id
            //     }
            // }).then((res)=>{
            //     // return
            // })
            document.getElementById('downLaodAticle').click()
        },
        //预览
        // previewWeekly(id){
        // 	//调用子组件的预览方法
        // 	//完整的地址应该是发布到服务器的地址，不应该是localhost:8080这样的
        // 	//Vue.prototype.ApiUrl 用来读取服务器地址 比如:
        //     //http://192.168.8.152:8080/MixShowView-Web/reports/preview/1
        //     // http://39.105.18.209:10564//web/viewer.html?file=/admin/profile/2/14652c99ecbc55b9cd01a801dfbc3aa0/%E8%BF%90%E6%B2%B3%E5%AD%A6%E7%A0%94%E7%A9%B6%20%E7%AC%AC2%E8%BE%91.pdf
        //     this.$refs.pdf.previewPDF('../assets/1.pdf')
        // },
        // //下载
        // downloadWeekly(id,fileName){
        // 	//调用子组件的下载方法
        //     this.$refs.pdf.downloadPDF('../assets/1.pdf','大运河')
        // },
        setShare() {
            //分享相关代码
            window._bd_share_config = {
                common: {
                    bdSnsKey: {},
                    bdText: '集刊全文数据库', //分享链接的标题
                    bdComment: '', //这里是推荐描述
                    bdDesc: '', //发送的时候，单独描述
                    bdUrl: window.location.href,
                    bdMini: '1',
                    bdMiniList: false,
                    bdPic: '', //这里是推荐图片
                    bdStyle: '1',
                    bdSize: '24'
                },
                share: {},
                selectShare: {
                    bdContainerClass: null,
                    bdSelectMiniList: ['sqq', 'weixin', 'tsina']
                }
            }
            const s = document.createElement('script')
            s.type = 'text/javascript'
            // s.src = '../../static/api/js/share.js?v=89860593.js?cdnversion=' + ~(-new Date() / 36e5)
            s.src = '/api/js/share.js?v=89860593.js?cdnversion=' + ~(-new Date() / 36e5)
            document.body.appendChild(s)
        },
        showQRCode() {
            var codeUrl = ''
            if (this.$route.query.pN) {
                var curUrl = window.location.href
                var i=curUrl.indexOf("&pN")
                codeUrl =curUrl.substring(0, i)
            } else {
                codeUrl = window.location.href
            }


            this.$nextTick(() => {
                var qrcode = new QRCode(this.$refs.qrcodeContainers, {
                    text: codeUrl,
                    width: 180,
                    height: 180,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
                qrcode.clear() // 清除代码
                qrcode.makeCode(codeUrl)
            })
        },
        shareWechatHandle() {
            this.shareWechat = !this.shareWechat
            document.getElementById('bdshare_weixin_qrcode_dialog').remove()
        },
        tabChange(iterm) {
            this.curTab = iterm.id
        },
        clickCopy(e) {
            var x, y
            var e = e || window.event
            return { x: e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft, y: e.clientY + document.body.scrollTop + document.documentElement.scrollTop }
        },

        getPreprintArticle(id) {
            var _this = this
            this.$http
                .get('/admin/api/aloneArticle/detail', {
                    params: {
                        id: id
                    }
                })
                .then(res => {
                    _this.detail = res.data.data
                    _this.pageName = _this.detail.title
                    if (_this.detail.attachment) {
                        _this.url = _this.detail.attachment.split('/admin')[1]
                        // console.log('_this.url', _this.url)
                    }
                    setTimeout(function() {
                        // var notes = $('.infoCont')
                        // console.log(3333, notes.find('span').text(),444,notes.find('a').children('span').children('strong').children('span').text())
                        // notes.find('a').css({'color': '#21d2f3 !important'})
                        // if (notes.find('a').children('span').children('strong').children('span').text() == '引用信息：') {
                        //     console.log(111,$(this), $(this).text())
                        // }
                        // var notes = document.getElementsByClassName('infoCont')
                        // for(var i=0;i<notes.length;i++){
                        // console.log(8,notes[i])
                        // notes[i].innerHTML = "<font style=\"color:red\">"+(i+1)+"</font>";
                        // }
                        // console.log(9,$('a').children('strong').text())
                        $('.infoCont p').each(function() {
                            if (
                                $(this)
                                    .children('a')
                                    .children('strong')
                                    .text() == '引用信息：'
                            ) {
                                $(this)
                                    .children('a')
                                    .css('cursor', 'default')
                                var copyInfo = $(this).next()
                                var copyText = $(this)
                                    .next()
                                    .text()
                                $(this)
                                    .next()
                                    .css('cursor', 'pointer')
                                $(this)
                                    .next()
                                    .click(function(e) {
                                        var c = document.getElementById('copy')
                                        c.style.display = 'none'
                                        c.setAttribute('data-clipboard-text', '')
                                        var copy = _this.clickCopy(e)
                                        var toLeft1 = copy.x + 10
                                        var toTop1 = copy.y - 45
                                        c.style.left = toLeft1 + 'px'
                                        c.style.top = toTop1 + 'px'
                                        c.innerHTML = '一键复制'
                                        var str1
                                        c.setAttribute('data-clipboard-text', copyText)
                                        c.style.display = 'block'
                                        var overTop1 = toTop1 + c.clientHeight - $(window).height() - $(window).scrollTop()
                                        var overLeft1 = toLeft1 + c.clientWidth - $(window).width() - $(window).scrollLeft()

                                        if (overTop1 > 0) {
                                            toTop1 = toTop1 - overTop1 - 2
                                            c.style.top = toTop1 + 'px'
                                        }
                                        if (overLeft1 > 0) {
                                            toLeft1 = toLeft1 - overLeft1 - 2
                                            c.style.left = toLeft1 + 'px'
                                        }

                                        // notes.onmouseout=function(){
                                        //     c.style.display = 'none';
                                        // }

                                        // }
                                    })
                            } else {
                            }
                        })
                    }, 500)
                })
        },
        getDetail(id) {
            var _this = this
            _this.$http
                .get('/admin/api/news/detail', {
                    params: {
                        id: id
                    }
                })
                .then(res => {
                    _this.detail = res.data.data
                    _this.pageName = _this.detail.title
                    setTimeout(function() {
                        $('.infoCont p').each(function() {
                            if (
                                $(this)
                                    .children('a')
                                    .children('strong')
                                    .text() == '引用信息：'
                            ) {
                                $(this)
                                    .children('a')
                                    .css('cursor', 'default')
                                var copyInfo = $(this).next()
                                var copyText = $(this)
                                    .next()
                                    .text()
                                $(this)
                                    .next()
                                    .css('cursor', 'pointer')
                                $(this)
                                    .next()
                                    .click(function(e) {
                                        var c = document.getElementById('copy')
                                        c.style.display = 'none'
                                        c.setAttribute('data-clipboard-text', '')
                                        var copy = _this.clickCopy(e)
                                        var toLeft1 = copy.x + 10
                                        var toTop1 = copy.y - 45
                                        c.style.left = toLeft1 + 'px'
                                        c.style.top = toTop1 + 'px'
                                        c.innerHTML = '一键复制'
                                        var str1
                                        c.setAttribute('data-clipboard-text', copyText)
                                        c.style.display = 'block'
                                        var overTop1 = toTop1 + c.clientHeight - $(window).height() - $(window).scrollTop()
                                        var overLeft1 = toLeft1 + c.clientWidth - $(window).width() - $(window).scrollLeft()

                                        if (overTop1 > 0) {
                                            toTop1 = toTop1 - overTop1 - 2
                                            c.style.top = toTop1 + 'px'
                                        }
                                        if (overLeft1 > 0) {
                                            toLeft1 = toLeft1 - overLeft1 - 2
                                            c.style.left = toLeft1 + 'px'
                                        }
                                    })
                            } else {
                            }
                        })
                    }, 500)
                })
        },
        getLatestNews() {
            var _this = this
            _this.$http.get('/admin/api/article/latest').then(res => {
                let x = res.data.data
                x.forEach((e, ind) => {
                    if (e.title) {
                        e.title = e.title.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ').replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '').replace(/&amp;/g,"&").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&#39;/g,"\'").replace(/&quot;/g,"\"").replace(/<\/?.+?>/g,"")
                    }
                })
                _this.latestNews = x
            })
        },
        getLastJk() {
            // http://jikan.b.com/admin/api/cp/list?ids=454,456,457,458,459
            var _this = this
            _this.$http.get('/admin/api/book/latest').then(res => {
                this.bkList = res.data.data
            })
        },
        toJk(id, name, num) {
            // this.$router.push({path:'/iedolHome',query:{id:id,num:num}})
            // this.$router.push({ name: 'iedolHome', params: { id: id } })
            // this.$router.push({ name: 'iedolHome', params: { id: id } })
            this.$router.push({ path: '/c/c', query: { id: id, pN: encodeURI(this.pageName) } })
            // , title: name
        }
    }
}
</script>
<style lang="scss" scoped>
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.back_box:hover {
    color: #333;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.el-col-17 {
    padding-right: 30px;
}
.braed {
    margin: 15px 0;
}
.braed >>> .el-breadcrumb__inner.is-link:hover {
    color: #333;
    cursor: pointer;
}
.tab-rt {
    padding-left: 10px;
    margin-top: -10px;
    .relevant-anthology-wrap {
        padding: 0 18px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        .com-title {
            div {
                img {
                    height: 29px;
                }
            }
        }
        .relevant-anthology {
            display: flex;
            justify-content: space-between;
            padding-top: 14px;
            img {
                width: 106px;
                height: 142px;
                margin-bottom: 10px;
            }
            .relevant-anthology-main {
                width: 162px;
                h5,
                p:nth-child(2) {
                    width: 162px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                h5 {
                    font-size: 16px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
                p {
                    font-size: 14px;
                    color: #666;
                    line-height: 22px;
                }
                p:nth-child(3) {
                    width: 162px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
        }
        ul {
            margin-bottom: 13px;
            li {
                width: 278px;
                box-sizing: border-box;
                padding-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 36px;
                height: 36px;
                font-size: 16px;
                color: #333333;
                cursor: pointer;
                position: relative;
            }
            li::before {
                position: absolute;
                left: 0;
                top: 18px;
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #ffac13;
            }
        }
    }
    .relevant-article-wrap {
        padding: 0 18px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        margin-top: 20px;
        .com-title {
            div {
                img {
                    height: 29px;
                }
            }
        }
        .relevant-article {
            ul {
                padding: 10px 0 14px;
                li {
                    line-height: 37px;
                    height: 37px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    position: relative;
                    font-size: 16px;
                    color: #333;
                    width: 278px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    box-sizing: border-box;
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 18px;
                }
            }
        }
    }
}
.contribut {
    padding-top: 4px;
    vertical-align: middle;
}
.contribut > div {
    width: 90px;
    height: 74px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background: #bd1a2d;
    padding-top: 14px;
}
.contribut > img {
    width: 246px;
    height: 74px;
    vertical-align: middle;
}
.guss h5 {
    position: relative;
    height: 46px;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 4px solid #bd1a2d;
    color: #bd1a2d;
}
.guss h5 span {
    position: absolute;
    top: -6px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    width: 176px;
    border-bottom: 4px solid #bd1a2d;
    border-bottom-right-radius: 3px;
}
.guss ul {
    padding: 10px;
    background: #f7f7f7;
}
.guss ul li {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.guss ul li:before {
    content: '';
    float: left;
    margin-top: 8px;
    margin-right: 14px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #b5b5b5;
    border-radius: 50%;
}
.guss ul li a {
    color: #292929;
}
.iedolImg {
    margin-bottom: 30px;
    width: 340px;
}
.iedolImg > img {
    width: 120px;
    height: 160px;
    display: inline-block;
    background: #ebf0f4;
    cursor: pointer;
}
.iedolMsg {
    width: 200px;
    padding: 4px 0 6px 20px;
}
.iedolMsg .itle {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 6px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
}
.iedolMsg .author {
    margin-bottom: 8px;
}
.iedolMsg .author span {
    margin-right: 4px;
}
.iedolMsg .abs {
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.iedolNum a {
    display: inline-block;
    width: 100px;
    line-height: 32px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    margin: 0 10px 10px 0;
}
.iedolNum a:hover,
.iedolNum a.active {
    color: #fff;
    border-color: #bd1a2d;
    background: #bd1a2d;
}

.ltCont {
    margin-top: 10px;
}
.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
    line-height: 18px;
    img {
        width: 20px;
        height: 18px;
        vertical-align: bottom;
    }
}
.pdate {
    // overflow: hidden;
}
.pdate > div {
    margin: 10px 12px 12px 0;
    width: 300px;
}
.pdate {
    .com-img {
        width: auto;
        position: relative;
        margin-right: 24px;
        img {
            width: 24px;
            height: 24px;
            margin-top: -4px;
            // margin-bottom: 20px;
            cursor: pointer;
            vertical-align: middle;
        }

        .com-img-wrap {
            position: absolute;
            left: 50%;
            top: 32px;
            transform: translateX(-50%);
            width: 80px;
            box-sizing: border-box;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            border-radius: 4px;
            display: none;
            border: 1px solid #eee;
            cursor: pointer;
            color: #333;
        }
        .code-img-wrap5 {
            width: 124px;
            height: 124px;
            text-align: center;
            line-height: 90px;
            background: #fff;
            padding-left: 6px;
            padding-top: 6px;
            div {
                width: 110px;
                height: 110px;
                overflow: hidden;
            }
        }
        .com-img-wrap::before {
            box-sizing: content-box;
            width: 0px;
            height: 0px;
            position: absolute;
            top: -16px;
            // right: 41px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
            border-bottom: 8px solid #ffffff;
            border-top: 8px solid transparent;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            display: block;
            content: '';
            z-index: 12;
        }
        .com-img-wrap::after {
            box-sizing: content-box;
            width: 0px;
            height: 0px;
            position: absolute;
            top: -18px;
            // right: 40px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
            border-bottom: 9px solid #cccccc;
            border-top: 9px solid transparent;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            display: block;
            content: '';
            z-index: 10;
        }
        // div::before {
        //     content: '';
        //     display: block;
        //     width: 0;
        //     height: 0;
        //     border-left: 5px solid transparent;
        //     border-right: 5px solid transparent;
        //     border-bottom: 10px solid red;
        //     position: absolute;
        //     top: -9px;
        //     left: 50%;
        //     transform: translateX(-50%);

        // }
    }
    .com-img:hover .com-img-wrap {
        display: block;
    }
    .com-img:first-child {
        margin-left: 9px;
        // div {
        //     background: red;
        // }
    }
}
.pdate span {
    margin-right: 8px;
}
.infoCont {
    padding: 20px 0;
    border-top: 1px solid #e6e6e6;
}
.infoCont >>> p {
    line-height: 22px;
    /* text-indent: 2em; */
}
.infoCont >>> img {
    max-width: 800px;
    margin: 0 auto;
    height: auto;
}
.infoCont .download-zip {
    cursor: pointer;
}
.pdate .bdsharebuttonbox {
    width: 100px;
    margin: 0;
}
.bdsharebuttonbox .bds_sqq.bds_sqq1 {
    background-image: url('../assets/img/qq.png');
    background-size: 100% 100%;
    background-position: 0 0;
}
.bdsharebuttonbox .bds_weixin.bds_weixin1 {
    background-image: url('../assets/img/wechat.png');
    background-size: 100% 100%;
    background-position: 0 0;
}
.wechat_box {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -170px;
    padding: 10px;
    width: 280px !important;
    height: 340px !important;
    background: #fff;
    border: solid 1px #d8d8d8;
    z-index: 11002;
    font-size: 12px;
}
.wechat_box > h5 {
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    line-height: 16px;
    height: 16px;
    position: relative;
    color: #000;
}
.wechat_box > h5 > a {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 0;
    color: #999;
    text-decoration: none;
    font-size: 16px;
}
.codeImg {
    // margin: 20px auto;
    // padding: 15px 10px;
    height: 200px;
    width: 200px;
}
.wechat_box > p {
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    color: #666;
}
.showPdf {
    position: relative;
    border: 2px solid #eee;
    background: #3f3f3f;
}
.showPdf .down {
    position: absolute;
    top: 9px;
    right: 130px;
}
.showPdf img {
    width: 15px;
}
.copy {
    width: 80px;
    text-align: center;
    border: 1px solid #bd1a2d;
    padding: 6px;
    background: #fff;
    font-weight: bold;
    font-size: 12px;
    color: #bd1a2d;
    cursor: pointer;
}
</style>
